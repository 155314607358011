$base-color: #75cf8f;
$primary-color: #ffffff;

.add_village_page_css {
  overflow-x: none !important;

  *body {
    box-sizing: border-box;
    scroll-margin-top: 125px;
  }

  .btn-next-add-new-village {
    color: $base-color;
  }

  .btn-next-add-new-village:hover {
    background-color: $primary-color !important;
    color: $base-color;
  }

  .add-village-container-sub-field {
    padding: 0 40px 0 30px;

    .main_heading {
      padding-bottom: 10px;
      font-weight: 400;
      font-size: 24px;
      height: 29px;
      width: 800px;
      color: #6b6b6b;
    }

    .label_form {
      padding-top: 20px;
      font-size: 19.15px;
      padding-left: 5px;
      color: #6b6b6b;
    }

    .bg-input-custom {
      background-color: $primary-color;
      border: none;
      border-radius: 8px;
    }

    .custom-width-upload-media {
      width: 100%;
    }

    .delete-button-custom {
      right: -5%;
      top: -20%;
      transform: scale(0.65);
    }

    .delete-button-custom:hover {
      cursor: pointer;
    }
  }

  .text-area {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    line-height: 31px;
    width: 372.89px;
    height: 47.56px;
    border-radius: 8px;
    padding-bottom: 5px;
  }

  .searchbtn {
    box-sizing: border-box;
    width: 147.68px;
    height: 39.42px;
    margin-left: 25px;
    margin-top: 10px;
    justify-items: center;
    background-color: $primary-color;
    color: $base-color;
    border: 1px solid $base-color;
    box-shadow: inset 0px 0px 4.26576px rgba(255, 255, 255, 0.25),
      inset 0px 1.7063px 1.7063px rgba(255, 255, 255, 0.25),
      inset 0px 0px 2.55946px rgba(255, 255, 255, 0.25),
      inset 0px 0.853152px 2.55946px rgba(255, 255, 255, 0.25);
    border-radius: 4.26576px;
  }

  .btn_next {
    box-sizing: border-box;
    width: 147.68px;
    height: 39.42px;
    left: 387.89px;
    top: 120px;
    margin-left: 25px;
    justify-items: center;
    background-color: $primary-color;
    color: $base-color;
    border: 1px solid $base-color;
    box-shadow: inset 0px 0px 4.26576px rgba(255, 255, 255, 0.25),
      inset 0px 1.7063px 1.7063px rgba(255, 255, 255, 0.25),
      inset 0px 0px 2.55946px rgba(255, 255, 255, 0.25),
      inset 0px 0.853152px 2.55946px rgba(255, 255, 255, 0.25);
    border-radius: 4.26576px;
  }

  .btn_next:hover {
    background-color: $base-color;
    color: $primary-color;
    box-shadow: inset 0px 0px 4.26576px rgba(255, 255, 255, 0.25),
      inset 0px 1.7063px 1.7063px rgba(255, 255, 255, 0.25),
      inset 0px 0px 2.55946px rgba(255, 255, 255, 0.25),
      inset 0px 0.853152px 2.55946px rgba(255, 255, 255, 0.25);
  }

  .searchbtn:hover {
    background-color: $base-color;
    color: $primary-color;
    box-shadow: inset 0px 0px 4.26576px rgba(255, 255, 255, 0.25),
      inset 0px 1.7063px 1.7063px rgba(255, 255, 255, 0.25),
      inset 0px 0px 2.55946px rgba(255, 255, 255, 0.25),
      inset 0px 0.853152px 2.55946px rgba(255, 255, 255, 0.25);
  }

  .btn_submit {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 147.68px;
    height: 39.42px;
    left: 387.89px;
    top: 120px;
    margin-left: 25px;
    justify-items: center;
    background-color: $primary-color;
    color: $base-color;
    border: 1px solid $base-color;
    box-shadow: inset 0px 0px 4.26576px rgba(255, 255, 255, 0.25),
      inset 0px 1.7063px 1.7063px rgba(255, 255, 255, 0.25),
      inset 0px 0px 2.55946px rgba(255, 255, 255, 0.25),
      inset 0px 0.853152px 2.55946px rgba(255, 255, 255, 0.25);
    border-radius: 4.26576px;
  }

  // .btn_submit:hover {
  //   background-color: $base-color;
  //   // color: white;
  //   box-shadow: inset 0px 0px 4.26576px rgba(255, 255, 255, 0.25),
  //     inset 0px 1.7063px 1.7063px rgba(255, 255, 255, 0.25),
  //     inset 0px 0px 2.55946px rgba(255, 255, 255, 0.25),
  //     inset 0px 0.853152px 2.55946px rgba(255, 255, 255, 0.25);
  // }

  input,
  textarea {
    padding-top: 10px;
  }

  .form-control {
    display: block;
    width: 100%;
  }

  .form_main {
    margin: 5px;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 70vh;
    padding: 10px;
    padding-left: 0;
    width: 100%;
    overflow-x: hidden;
  }

  .btn_browse {
    margin-top: 50px !important;
    margin-left: 20px !important;
    background: $base-color;
    border-radius: 8px;
    border-radius: 5px;
    padding: 8px 20px;
    color: $primary-color;
    border: none;
  }

  .add-new-field-btn {
    margin-left: 10px !important;

    background: $base-color;
    border-radius: 8px;
    border-radius: 5px;
    padding: 8px 20px;
    color: $primary-color;
    border: none;
  }

  .remove-field-btn {
    position: absolute !important;
    margin-left: 10px !important;
    background: $base-color;
    border-radius: 8px;
    border-radius: 5px;
    padding: 8px 20px;
    color: $primary-color;
    border: none;
  }

  .dropdown-tog {
    padding-left: 10px;
    padding-top: 40px;
  }

  .drop-toggle {
    width: 240.68px;
    height: 39.42px;
    background: $primary-color !important;
    border: 1px solid #cecece !important;
    border-radius: 8px !important;
  }

  @media screen and (max-width: 1600) {
    .btn_browse {
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }

  @media (max-width: 1334px) and (min-width: 1180px) {
    *body {
      font-size: 16px !important;
    }

    .form_main {
      height: 640px;
    }

    .main_heading {
      width: 590px;
    }

    .drop-toggle {
      width: 180.68px;
      height: 39.42px;
    }
  }

  @media (max-width: 2000px) and (min-width: 1325px) {
    *body {
      font-size: 16px !important;
    }

    .label_form {
      padding-top: 10px;
      font-size: 19.15px;
    }

    .main_heading {
      width: 700px;
    }

    .drop-toggle {
      width: 200.68px;
      height: 39.42px;
    }
  }

  @media (max-width: 1180px) and (min-width: 900px) {
    *body {
      font-size: 16px !important;
    }

    .form_main {
      height: 695px;
      padding: 10px;
    }

    .label_form {
      padding-top: 8px;
      font-size: 17.15px;
    }

    .main_heading {
      padding-bottom: 10px;
      font-weight: 400;
      font-size: 22px;
      height: 29px;
      width: 410px;
    }

    .btn_browse {
      padding: 10px;
      font-size: 14px;
    }

    .btn_submit,
    .btn_next {
      width: 100.68px;
      height: 30.42px;
    }

    .dropdown-tog {
      padding-top: 44px;
    }

    .drop-toggle {
      width: 150.68px;
      height: 39.42px;
    }
  }

  @media (max-width: 900px) and (min-width: 800px) {
    *body {
      font-size: 16px !important;
    }

    .form_main {
      max-height: 700px;
      padding: 10px;
    }

    .label_form {
      padding-top: 8px;
      font-size: 17.15px;
    }

    .main_heading {
      padding-bottom: 10px;
      font-weight: 400;
      font-size: 22px;
      height: 29px;
      width: 350px;
    }

    .btn_browse {
      padding: 10px;
      font-size: 14px;
    }

    .btn_submit,
    .btn_next {
      width: 100.68px;
      height: 30.42px;
    }

    .devlop label {
      font-size: 16px !important;
    }

    .devlop .extra {
      margin-top: 22px;
    }

    .dropdown-tog {
      padding-top: 67px;
      font-size: 12px;
    }

    .drop-toggle {
      width: 100.68px;
      height: 35.42px;
    }
  }

  @media (max-width: 800px) and (min-width: 768px) {
    *body {
      font-size: 16px !important;
    }

    .form_main {
      height: 695px;
      padding: 10px;
    }

    .label_form {
      padding-top: 8px;
      font-size: 17.15px;
    }

    .main_heading {
      padding-bottom: 10px;
      font-weight: 400;
      font-size: 22px;
      height: 29px;
      width: 350px;
    }

    .btn_browse {
      padding: 5px;
      font-size: 14px;
    }


    .btn_submit,
    .btn_next {
      width: 100.68px;
      height: 30.42px;
    }

    .devlop label {
      font-size: 14px !important;
    }

    .devlop .extra {
      margin-top: 20px;
    }

    .dropdown-tog {
      padding-top: 62px;
      font-size: 12px;
    }

    .drop-toggle {
      width: 100.68px;
      height: 35.42px;
    }
  }

  @media (max-width: 768px) and (min-width: 450px) {
    .mb-col .form-row {
      display: flex;
    }

    .text-area {
      height: 44px;
    }

    .btn_browse {
      margin-top: 50px;
      margin-left: 10px;
      padding: 8px 20px;
      font-size: 15.15px;
    }

    .dropdown-tog {
      padding-left: 10px;
      padding-top: 40px;
    }

    .drop-toggle {
      width: 104.68px;
      height: 35.42px;
      font-size: 15.15px;
    }

    label {
      font-size: 15.15px;
    }

    .form-control {
      font-size: 15.15px;
    }
  }

  @media (max-width: 450px) and (min-width: 350px) {
    .mb-col {
      display: flex !important;
      flex-direction: column !important;
    }

    .text-area {
      height: 40px;
    }

    .mb-col .col-md-4 {
      width: 322px !important;
    }

    .btn_next {
      box-sizing: border-box;
      width: 104.68px;
      height: 35.42px;
      font-size: 15.15px;
    }

    .btn_submit {
      box-sizing: border-box;
      width: 104.68px;
      height: 35.42px;
      font-size: 15.15px;
    }

    .btn_browse {
      position: none !important;
      margin-top: 41px;
      margin-left: 10px;
      padding: 6px 17px;
      font-size: 15.15px;
    }

    .searchbtn {
      width: 104.68px;
      height: 35.42px;
      margin-left: 25px;
      margin-top: 3px;
      font-size: 15.15px;
    }

    .label_form {
      padding-top: 20px;
      font-size: 15.15px;
    }

    .form-control {
      font-size: 13px;
    }

    .label_form {
      padding-top: 20px;
      font-size: 15.15px;
    }

    .dropdown-tog {
      padding-left: 10px;
      padding-top: 14px;
    }

    .drop-toggle {
      width: 104.68px;
      height: 35.42px;
      font-size: 15.15px;
    }

    label {
      font-size: 15.15px;
    }

    .final_submit {
      margin-bottom: 25px !important;
    }
  }

  @media (max-width: 350px) {
    .mb-col {
      display: flex !important;
      flex-direction: column !important;
    }

    .text-area {
      height: 36px;
    }

    .mb-col .col-md-4 {
      width: 100% !important;
    }

    .btn_next {
      box-sizing: border-box;
      width: 70px;
      height: 32.42px;
      font-size: 13.15px;
    }

    .btn_submit {
      box-sizing: border-box;
      width: 70px;
      height: 32.42px;
      font-size: 13.15px;
    }

    .btn_browse {
      margin-top: 41px;
      font-size: 13.15px;
    }

    .final_submit {
      margin-bottom: 57px !important;
    }

    .searchbtn {
      width: 80px;
      height: 32.42px;
      margin-left: 25px;
      margin-top: 2px;
      font-size: 13.15px;
    }

    .label_form {
      padding-top: 20px;
      font-size: 13.15px;
    }

    .form-control {
      font-size: 13.15px;
    }

    .main_heading {
      padding-bottom: 1px;
      font-weight: 400;
      font-size: 18px;
      height: 33px;
      color: black;
    }

    .label_form {
      padding-top: 20px;
      font-size: 13.15px;
    }

    .dropdown-tog {
      padding-left: 10px;
      padding-top: 14px;
    }

    .drop-toggle {
      width: 80px;
      height: 32.42px;
      font-size: 13.15px;
    }

    label {
      font-size: 12px !important;
    }
  }

  .final_submit {
    float: right;
    display: flex;
  }

  .btn_submit.hidden {
    opacity: 0.5;
    pointer-events: none;
  }

  @media (max-width: 768px) {
    .form_main {
      padding: 10px 10px 10px 0 !important;
      height: fit-content;
    }

    .marginPut {
      margin-bottom: 125px;
      padding: 0px 8px;
    }
  }

  @media (max-width: 300px) {
    .btn_browse {
      margin-top: 41px;
      margin-left: 9px !important;
      font-size: 13.15px;
    }

    .media-textarea {
      width: 70% !important;
    }

    .fetival_field {
      padding: 0px 8px !important;
    }
  }

  .bg-green-custom {
    background-color: $base-color !important;
    color: $primary-color !important;
    box-shadow: none !important;
  }

  .bg-green-custom:hover {
    background-color: #75cf8f !important;
  }

  .dynamic-button-add-temp {
    padding-left: 5px;
  }

  .bg-bottom-buttons-add-village {
    bottom: 0;
    width: 100vw;
    position: fixed;
  }

  .bottom-buttons-add-village {
    position: absolute;
    bottom: 0;
    right: 10%;
  }

  .padding-full-page-custom {
    padding-bottom: 100px !important;
  }

  @media (max-width: 500px) {
    .add-village-container-sub-field {
      padding: 0 0px !important;
    }
  }
}