@import url('https://fonts.googleapis.com/css2?family=Hind+Madurai:wght@400;500&display=swap');


.village_list_table {
    height: 74vh;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 100;
}

.rank-village {
    width: "40px";
    height: "24px";
}

.page {
    .open-passport-logo {
        color: #ffdc56;
        position: absolute;
        top: 50%;
        right: 5%;
    }

    .page-content.right {
        padding: 20px 0 30px 0;
        display: flex;
    }

    .page-right.right {
        display: flex;
    }

    .page-content {
        height: 101%;
        align-items: stretch;

        .page-right {
            height: 80%;
            align-items: stretch;
        }
    }
}

.page-right.right.pass_page_image {
    width: 536px;
    height: 289px;
}

.txtGalaxy {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #55B971;
    padding-left: 7px;
    margin-top: 3px;
    text-align: end;
}

.state_name {
    color: #7A7A7A;
    font-size: 15px;
}

@media (min-width: 1330px) and (max-width: 1800px) {
    .image-at-end-pass-cover {
        padding-top: 20px;
    }
}

@media (min-width: 1100px) and (max-width: 1367px) {
    .part_two {
        padding: 10px !important;
    }
}

@media (min-width: 768px) and (max-width: 1100px) {
    .sub_book {
        margin: auto;
        padding-bottom: 30px;
    }

    .image-at-end-pass-cover {
        padding-top: 0px;

    }

    .part-one-desk {
        gap: 10px !important;
    }
}

@media (max-width: 768px) {
    .sub_book {
        margin: auto;
        padding-bottom: 30px;
    }

    .table-content {
        display: flex;
        flex-direction: column;
    }

    .txtGalaxy {
        padding-left: 10px !important;
        margin-left: 10px !important;
        font-size: 12px !important;
    }

    .state_name {
        font-size: 12px;
    }

    .Village_name {
        font-size: 14px;
    }
}

@media (max-width: 580px) {
    .part_two {
        padding: 10px !important;
    }

    .leaderboard-design {
        padding: 8px 10px !important;
    }

    .col-lg-6.col-md-12.sub_book.village_list_table {
        margin-top: -46px;
    }
}

@media (max-width: 500px) {
    .head_logo {
        height: 60px !important;
    }

    .logo_image {
        padding: 0 !important;
    }

    .village_avtar_image.text-center.rounded-circle.leader2 {
        margin-right: 3px !important;
    }

    .txtGalaxy {
        padding-left: 5px !important;
        padding-right: 3px !important;
        font-size: 10px !important;
    }

    .state_name {
        font-size: 10px;
    }

    .Village_name {
        font-size: 12px;
    }

}

@media (max-width: 375px) {
    .village_avtar_image.text-center.rounded-circle.leader2 {
        margin-right: 2px !important;
    }
}


@media (min-width: 768px) {
    .village_avtar_image.text-center.rounded-circle.leader2 {
        margin-left: 15px !important;
    }
}

@media (max-width: 338px) {

    .leaderboard-design {
        padding: 5px 1px !important;
    }

    .village_avtar_image.text-center.rounded-circle.leader2 {
        height: 30px !important;
        margin-right: -7px !important;
    }

    .imgGalaxy {
        height: 30px !important;
    }

    .txtGalaxy {
        padding-left: 2px !important;
        padding-right: 3px !important;
        font-size: 10px !important;
    }
}

@media (max-width: 391px) {
    .txtGalaxy {
        font-size: 9px !important;
    }

    .imgGalaxy {
        width: 30px !important;
        height: 40px !important;
    }

    .village_avtar_image.text-center.rounded-circle.leader2 {
        height: 40px !important;
        width: 40px !important;
    }

    .Village_name {
        font-size: 11px;
    }

    .rank-village {
        width: 20px !important;
        height: 20px !important;
    }
}

.loadtxt {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
}

.passport-left-page {
    border: 4px solid #8A5D3B;
    background: #EAEAEA;
    height: 71%;
    position: absolute;
    top: 12%;
    left: 20%;

    .left-dots {
        position: absolute;
        top: 3%;
        left: 2%;
        height: 96%;
        color: #D8D8D8;
    }

    .right-dots {
        position: absolute;
        top: 3%;
        right: 2%;
        height: 96%;
        color: #D8D8D8;
    }

    .barcode {
        position: absolute;
        bottom: 5%;
        left: 33%;
    }

    .head_logo {
        position: absolute;
        top: 13%;
    }
}

.passport-end-page {
    border: 4px solid #8A5D3B;
    background: #EAEAEA;
    height: 75%;
    top: 10%;
    position: absolute;
    left: 13%;
    width: 76%;

    .left-dots {
        position: absolute;
        top: 3%;
        left: 2%;
        height: 96%;
        color: #D8D8D8;
    }

    .right-dots {
        position: absolute;
        top: 3%;
        right: 2%;
        height: 96%;
        color: #D8D8D8;
    }

    .barcode {
        position: absolute;
        bottom: 3%;
        left: 24%;
    }

    .barcode img {
        width: 70px;
    }

    .head_logo {
        position: absolute;
        top: 13%;
    }
}

.passport-right-box {
    border: 4px solid #8A5D3B;
    position: absolute;
    width: 482px;
    top: 10%;
    left: 11%;
    height: 227px;
    background-color: #EAEAEA;

    .fontTicket {
        position: absolute;
        top: 19%;
        left: 4%;
    }

    .UserName {
        padding-top: 60px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        color: #3B2A1F;
    }

    .Logo {
        position: absolute;
        right: 6%;
        top: 14%;
    }

    .swipeBox {
        position: absolute;
        bottom: 8%;
        left: 16%;
    }

    .open-text {
        font-family: 'Rokkitt';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        color: #3B2A1F;
        background-color: #D2D2D2;
        width: 239px;
        text-align: center;
    }

    .village-image {
        width: 222px;
        padding: 8px;
    }

    .logo-inside {
        padding: 0px 12px;
        position: absolute;
        bottom: -3%;
        right: 0%;
    }

    .village-details-passport {
        font-family: 'PT Mono';
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        padding-top: 9px;
        color: #000000;
    }
}

.page-image img {
    width: 150px;
    height: 107px;
}


.blackDotes {
    position: absolute;
    top: 9%;
}

@media (min-width: 1525px) {
    .col-md-6.attach-content {
        width: 180px !important;
    }
}

@media (min-width: 1315px) and (max-width: 1525px) {
    .passport-right-box {
        border: 4px solid #8A5D3B;
        position: absolute;
        width: 361px;
        top: 10%;
        left: 11%;
        height: 227px;
    }

    .logo-inside {
        padding: 0px 12px;
        position: absolute;
        bottom: -6% !important;
        right: 23%;
    }

    .page-image img {
        width: 157px;
        height: 102px;
    }

    .swipeBox img {
        width: 246px;
    }

    img.front-page-right {
        width: 412px;
        height: 288px;
    }

    img.front_page_left {
        width: 300px;
        height: 300px;
    }
}

@media (min-width: 993px) and (max-width: 1315px) {
    .blackDotes {
        position: absolute;
        top: 7%;
        z-index: 1000;
        left: -21%;

        img {
            height: 191px;
        }
    }

    .stf__item ::after {
        height: 100px !important;
    }

    .stf__item {
        height: 200px !important;
    }

    .passport-right-box {
        width: 259px;
        height: 189px;
        top: 7%;
        left: -8%;

        .village-details-passport {
            font-size: 0.7rem;
        }

        .open-text {
            width: 163px;
            font-size: 10px;
        }

        .swipeBox {
            left: 8%;
        }

        .Logo img {
            width: 99px;
        }

        .fontTicket {
            width: 88px;
        }

        .UserName {
            padding-top: 48px;
            width: 135px !important;
        }

        .logo-inside {
            padding: 0px 12px;
            position: absolute;
            bottom: -4%;
            bottom: -5%;
            width: 101px;
        }

        .logo-inside img {
            width: 84px;
        }

        .swipeBox img.swipeBox-image {
            width: 200px !important;
            height: 20px;
        }
    }

    .page-image img {
        width: 125px;
        height: 80px;
    }

    .front-page-right {
        position: absolute;
        left: -20%;
    }

    img.front-page-right {
        width: 307px;
        height: 230px;
    }

    img.front_page_left {
        width: 156px;
        height: 230px;
    }

    .passport-end-page {
        border: 4px solid #8A5D3B;
        background: #EAEAEA;
        height: 95%;
        top: 7%;
        position: absolute;
        left: 11%;
        width: 59%;

        .barcode img {
            width: 47px;
        }
    }

    .front-page-left {
        height: 229px;
    }

    .village_list_table {
        padding: 0px 2px;
    }

    .sub_book .stf__parent {
        left: -13% !important;
        margin: auto !important;
    }
}

@media (min-width: 600px) and (max-width: 993px) {
    .sub_book .stf__parent {
        left: -14%;
        margin: auto;
    }

    .passport-right-box {
        border: 4px solid #8A5D3B;
        position: absolute;
        width: 361px;
        top: 10%;
        left: 11%;
        height: 227px;

        .logo-inside {
            padding: 0px 12px;
            position: absolute;
            bottom: -3%;
            right: -1%;
        }

        .logo-inside img {
            padding: 0px 12px;
            position: absolute;
            bottom: -6% !important;
            right: 4% !important;
        }

        .village-details-passport {
            padding: 4px 9px;
            font-size: 0.8rem;
        }
    }

    .page-image img {
        width: 156px;
        height: 102px;
    }

    .swipeBox img {
        width: 246px;
        height: 20px;
    }

    img.front-page-right {
        width: 412px;
        height: 288px;
    }

    img.front_page_left {
        width: 300px;
        height: 300px;
    }
}

@media (min-width: 450px) and (max-width: 600px) {
    .sub_book .stf__parent {
        left: -2%;
        margin: auto;
    }

    .blackDotes {
        position: absolute;
        top: 7%;
        z-index: 1000;
        left: -21%;
    }

    .blackDotes img {
        height: 191px;
    }

    .front-page-right {
        position: absolute;
        left: -20%;
    }

    img.front-page-right {
        width: 276px;
        height: 230px;
    }

    .passport-right-box {
        width: 235px;
        height: 189px;
        top: 7%;
        left: -8%;

        .fontTicket {
            width: 88px;
        }

        .UserName {
            padding-top: 48px;
            width: 87px !important;
            font-size: 0.8rem;
        }

        .swipeBox {
            position: absolute;
            bottom: 8%;
            left: 10%;
        }

        .swipeBox img.swipeBox-image {
            width: 200px !important;
            height: 20px;
        }

        .Logo img {
            width: 81px;
        }

        .village-details-passport {
            font-size: 0.7rem;
            padding: 0px 9px;
        }

        .logo-inside {
            bottom: 2%;
            right: -1%;
        }

        .logo-inside img {
            width: 84px;
        }

        .open-text {
            width: 160px;
        }
    }

    .stf__parent {
        min-width: 190px;
        min-height: 300px;
        width: 100%;
        max-width: 380px;
    }

    .page-image img {
        width: 177px;
        height: 79px;
    }

    .passport-end-page {
        border: 4px solid #8A5D3B;
        background: #EAEAEA;
        height: 63%;
        top: 7%;
        position: absolute;
        left: 11%;
        width: 59%;

        .barcode img {
            width: 47px;
            padding-top: 5px;
        }
    }

    .front-page-left {
        height: 229px;

        img {
            width: 156px;
            height: 230px;
        }
    }
}

.stf__parent {
    .stf__wrapper {
        padding-bottom: 0px !important;
    }
}

@media (min-width: 400px) and (max-width: 450px) {
    .head_logo {
        height: 45px !important;
    }

    .passport-right-box .village-details-passport {
        font-size: 10px;
        padding: 0px 3px;
        color: #000000;
    }

    .sub_book .stf__parent {
        left: -1%;
        margin: auto;
        height: 200px;
    }

    .col-lg-6.col-md-12.sub_book.village_list_table {
        margin-top: 4px !important;
    }

    .front-page-left {
        height: 157px;
        position: absolute;
        top: 1%;
        left: -23%;
        width: 96px;
    }

    .passport-end-page {
        border: 2px solid #8A5D3B;
        background: #EAEAEA;
        height: 81%;
        top: 13%;
        position: absolute;
        left: -28%;
        width: 65%;

        .barcode {
            bottom: 1%;
            left: 32%;
        }

        .barcode img {
            width: 22px;
        }
    }

    .front-page-right img {
        width: 100px;
        height: 149px;
    }

    .passport-right-box {
        border: 2px solid #8A5D3B;
        background: #EAEAEA;
        height: 81%;
        top: 14%;
        position: absolute;
        left: -28%;
        width: 184%;

        .logo-inside {
            position: absolute;
            bottom: -4%;
            right: 2%;
        }

        .logo-inside img {
            width: 42px;
        }

        .UserName {
            padding-top: 31px;
            font-size: 0.6rem;
            width: 92px;
        }

        .swipeBox {
            position: absolute;
            bottom: 5%;
            left: 5%;
        }

        .village-image {
            width: 79px;
            padding: 3px;
        }

        .open-text {
            width: 100px;
        }

    }

    img.swipeBox-image {
        width: 128px;
    }

    .page-content.left {
        width: 90px !important;
    }

    .page-right.right.pass_page_image {
        width: 100px !important;
    }

    .front-page-right {
        position: absolute;
        left: -50%;
        top: 3%;
        width: 215px;
        height: 150px;
    }

    .blackDotes {
        position: absolute;
        top: 13%;
        z-index: 200;
        left: -51%;
    }

    .blackDotes img {
        height: 121px;
    }

    .page-image img {
        width: 116px;
        height: 53px;
    }

    img.national-logo {
        width: 70px;
    }

    img.fontTicket {
        width: 58px;
    }
}

@media (min-width: 280px) and (max-width: 400px) {
    .head_logo {
        height: 45px !important;
    }

    .col-lg-6.col-md-12.sub_book.village_list_table {
        margin-top: 12px;
    }

    .passport-right-box .village-details-passport {
        font-size: 0.5rem;
        padding: 0px 3px;
        color: #000000;
    }

    .sub_book .stf__parent {
        margin: auto;
    }

    .front-page-left {
        height: 157px;
        position: absolute;
        top: 1%;
        left: -18%;
        width: 96px;
    }

    .passport-end-page {
        border: 2px solid #8A5D3B;
        background: #EAEAEA;
        height: 81%;
        top: 14%;
        position: absolute;
        left: -21%;
        width: 63%;

        .barcode {
            bottom: 1%;
        }

        .barcode img {
            width: 30px;
        }
    }

    .front-page-right img {
        width: 100px;
        height: 149px;
    }

    .passport-right-box {
        border: 2px solid #8A5D3B;
        position: absolute;
        width: 172px;
        top: 13%;
        left: -41%;
        height: 121px;

        .logo-inside {
            position: absolute;
            bottom: -4%;
            right: 2%;
        }

        .logo-inside img {
            width: 42px;
        }

        .UserName {
            padding-top: 31px;
            font-size: 0.6rem;
            width: 79px;
        }

        .swipeBox {
            position: absolute;
            bottom: 5%;
            left: 5%;
        }

        .village-image {
            width: 79px;
            padding: 3px;
        }

        .open-text {
            width: 99px;
        }

    }

    img.swipeBox-image {
        width: 128px;
    }

    .page-content.left {
        width: 90px !important;
    }

    .page-right.right.pass_page_image {
        width: 100px !important;
    }

    .front-page-right {
        position: absolute;
        left: -50%;
        top: 3%;
        width: 189px;
        height: 147px;
    }

    .blackDotes {
        position: absolute;
        top: 14%;
        z-index: 200;
        left: -51%;
    }

    .blackDotes img {
        height: 121px;
    }

    .page-image img {
        width: 133px;
        height: 53px;
    }

    img.national-logo {
        width: 70px;
    }

    img.fontTicket {
        width: 58px;
    }
}

.pagination {
    display: flex;
    justify-content: space-evenly;
    background-color: #f8f9fa;
    color: #75cf8f;
}
.flipbook {
    flex: 2 1;
  }
  .page-turn-animation {
    animation: none !important;
  }
//   .stf__item{
//     transform: none !important;
//     animation: none !important;
//     box-shadow: #fff !important;
//     transform-style: flat !important;
//     transform-origin: none !important;
//     z-index: 0 !important;
//     transition: none !important;
//     position: relative;
//   } 
//    .page{
//     transform: none !important;
//     animation: none !important;
//     box-shadow:  #fff !important;
//     transform-style: flat !important;
//     transform-origin: none !important;
//     z-index: 0 !important;
//     transition: none !important;
//    }