$base-color: #75cf8f;

.add-artist {
  input.form-control {
    background: #dadada;
    border-radius: 8px;
  }

  .label_form {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
  }
}

.media-card-content:hover .media-main-image {
  filter: brightness(70%);
}

.artist-heading-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}

input.file-upload {
  visibility: hidden;
  width: 30%;
}

.btn_browse {
  background: $base-color;
  border-radius: 5px;
  padding: 10px 30px;
  color: #fff;
  border: none;
}

.btn_cart_media {
  color: $base-color;
  background: #fff;
  width: 60%;
  border: 1px solid $base-color;
  border-radius: 4.26576px;
  padding: 7px 15px;
  font-size: 14px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn_buy_media {
  background: $base-color;
  color: #fff;
  width: 35%;
  border: 1px solid $base-color;
  border-radius: 4.26576px;
  padding: 7px 15px;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn_submit {
  background-color: #fff;
  color: $base-color;
  border: 1px solid $base-color;
  border-radius: 4.26576px;
  padding: 7px 15px;
  font-size: 17px;
}

button.btn_submit_plus {
  border: none;
  background: none;
  color: $base-color;
}

.recentLoading.media {
  height: 80px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.artist_profile {
  .account-profile {
    padding-right: 50px;
  }

  overflow-x: hidden;
  width: 100%;
  position: relative;

  select {
    margin-top: 4px;
  }

  .edit-artist-profile {
    hr:not([size]) {
      height: 2px;
      width: 100%;
    }

    .form-control-plaintext {
      width: 100% !important;
    }

    .form-label {
      margin-bottom: -5px;
      margin-top: 20px;
    }

    .image_upload {
      margin-top: 15px;

      .upload-pic {
        border-radius: 0 !important;
      }
    }
  }
}

.common-edit {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10px;

  .edit-text {
    margin-top: 30%;
    font-weight: 600;
    font-size: 22.2998px;
    line-height: 124.5%;
    color: #ffffff;
  }
}

.add-edit-media {
  hr:not([size]) {
    height: 2px;
    width: 90%;
  }

  .form-control-plaintext {
    width: 90%;
    height: 30px;
  }

  .form-label {
    margin-bottom: -5px;
    margin-top: 20px;
  }

  .image_upload {
    margin-top: 15px;

    .upload-pic {
      border-radius: 0 !important;
    }
  }

  .modal-body {
    padding: 0px 30px;
    margin-bottom: 30px;
  }

  .modal-header {
    border: none;
    padding: 30px 40px;
  }

  .modal-footer {
    display: none;
  }

  .file-upload-input {
    z-index: 999;
    opacity: 0;
    width: 320px;
    height: 200px;
    position: absolute;
    right: 0px;
    left: 0px;
    margin-right: auto;
    margin-left: auto;
  }
}

img.edit-media-image {
  height: 236px;
  width: 300px;
  object-fit: contain;
  border: 1px solid #f5fff8;
  border-radius: 10px;
}

.view-artist-card {
  .main_con {
    margin: 0;
    padding: 0;

    .mobile-image-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .image-section {
      // display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    img {
      width: 80%;
      height: 80%;
      object-fit: contain;
    }

    .details {
      ::-webkit-scrollbar {
        width: 0.5px !important;
      }

      ::-webkit-scrollbar-track {
        background: #fff;
      }

      ::-webkit-scrollbar-thumb {
        background: #fff;
      }

      overflow-y: auto;
      align-items: baseline;
      line-height: 40px;
      width: 100%;

      .content {
        width: 65%;
        font-weight: 400;
      }

      .heading {
        font-size: 19px;
        width: 37%;
        justify-content: left;
        color: #7d7d7d;
        font-weight: 400;
      }
    }
  }

  @media (max-width: 960px) {
    .details .heading {
      font-size: 15px;
    }

    span {
      font-size: 15px;
    }
  }
}

.App-Artist {
  overflow-x: hidden;
  // overflow-y: scroll;

  .search {
    width: 20%;
    padding: 14px;
    justify-content: center !important;
    background-color: $base-color;
    color: #fff;
    border-radius: 8px !important;
    border: none;
    margin-left: 20px !important;
  }

  .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 60%;
  }

  span {
    color: white;
  }

  .art {
    font-weight: 200;
  }

  input {
    border: none !important;
    border-color: #fff;
    width: 75% !important;
    font-size: 14px !important;
    border-radius: 8px !important;
    box-shadow: 0 100px 80px rgb(0 0 0 / 2%),
      0 64.8148px 46.8519px rgb(0 0 0 / 2%),
      0 38.5185px 25.4815px rgb(0 0 0 / 1%), 0 20px 13px rgb(0 0 0 / 1%),
      0 8.14815px 6.51852px rgb(0 0 0 / 1%),
      0 1.85185px 3.14815px rgb(0 0 0 / 0%);
  }

  screen .know {
    color: $base-color;
  }
}

.description-text {
  font-size: 14px;
}

@media (max-width: 767px) {
  .view-artist-card .main_con img {
    width: 190px !important;
    height: 190px !important;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid white;
  }

  .view-artist-card.mobile-theme {
    margin-bottom: 80px;
  }

  .artist_profile .edit-artist-profile {
    .form-control-plaintext {
      width: 100% !important;
    }

    hr:not([size]) {
      height: 2px;
      width: 100%;
    }
  }

  .th-12 {
    font-size: 14px;
  }

  .row.py-2 .th-12 {
    font-size: 11px;
  }

  .artist_profile select {
    margin-top: 3px !important;
  }

  .artist-mobile-scroll {
    margin-bottom: 139px;
  }
}

@media (min-width: 992px) and (max-width: 1250px) {
  .custom.artistcard {
    width: 33.33% !important;
  }

  .description-text {
    font-size: 13px !important;
  }

  .price-text,
  .name-text {
    font-size: 14px !important;
  }

  .view-artist-card {
    .heading {
      font-size: 16px !important;
    }

    .content {
      font-size: 14px !important;
    }
  }
}

@media (max-width: 992px) {
  img.edit-media-image {
    height: 200px !important;
    width: 200px !important;
    object-fit: contain;
  }

  .custom.artistcard {
    width: 50%;
  }

  .description-text {
    font-size: 12px !important;
  }

  .App-Artist {
    margin-bottom: 100px;

    .input-group {
      width: 100% !important;
    }
  }

  .price-text,
  .name-text {
    font-size: 14px !important;
  }

  .view-artist-card {
    .heading {
      font-size: 14px !important;
    }

    .content {
      font-size: 13px !important;
    }

    .email-split {
      padding: 0 !important;
    }

    .details {
      line-height: 30px !important;
    }
  }
}

@media (min-width: 470px) and (max-width: 640px) {
  .custom.artistcard {
    width: 100% !important;
  }

  .description-text {
    font-size: 12px !important;
  }

  .price-text,
  .name-text {
    font-size: 13px !important;
  }

  .App-Artist {
    margin-bottom: 50px;

    .input-group {
      input.form-control {
        width: 60% !important;
      }

      .search {
        width: 35% !important;
      }
    }
  }

  .artist-mobile-scroll {
    margin-bottom: 39px;
  }
}

@media (min-width: 380px) and (max-width: 470px) {
  .custom.artistcard {
    width: 100% !important;
  }

  .price-text,
  .name-text {
    font-size: 12px !important;
  }

  .App-Artist {
    .search {
      width: 25%;
      padding: 10px;
      border-radius: 8px !important;
      border: none;
      margin-left: 20px !important;
    }

    .input-group {
      input.form-control {
        width: 50% !important;
      }

      .search {
        width: 35% !important;
      }
    }
  }

  .artist-mobile-scroll {
    margin-bottom: 90px;
  }
}

@media screen and (max-width: 380px) {
  .artist_profile.mobile-theme {
    margin-bottom: 100px;
  }

  .custom.artistcard {
    width: 100% !important;
  }

  .view-artist-card.mobile-theme {
    margin-bottom: 70px;
  }

  .App-Artist {
    margin-bottom: 140px;

    .input-group {
      input.form-control {
        width: 50% !important;
      }

      .search {
        width: 25% !important;

        span.p-1 {
          display: none;
        }

        .p-2 img {
          height: 20px;
        }
      }
    }
  }

  .btn_submit {
    padding: 5px;

    button.btn_submit_plus {
      font-size: 12px;
    }
  }
}

.main_con .row {
  margin: 0 !important;
}

.image_upload .upload-pic {
  padding: 15px !important;
  margin-top: 10px;
}

@media (min-width: 1000px) and (max-width: 1250px) {
  .artist-circle {
    height: 200px !important;
    width: 200px !important;
  }
}

.displayMediaArtist {
  display: none;
}

.account-detail {
  line-height: 3.4;
}

@media (min-width: 768px) and (max-width: 1000px) {
  .displayMediaArtist {
    display: block;
    padding-left: 10px;
    // .account-profile {
    //   width: 33.33%;
    // }
  }

  .web-media-responsive {
    width: 100%;
    flex-direction: column;

    .image-part {
      width: 100%;
    }
  }
}

@media (max-width: 280px) {
  button.btn_submit_plus {
    display: none;
  }
}

@media (max-width: 425px) {
  .artist_profile .edit-artist-profile .form-label {
    margin-top: 15px !important;
    font-size: 14px !important;
  }

  .unique_id_display {
    font-size: 11px !important;
  }

  .email_profile {
    font-size: 11px !important;
  }
}

.description-text {
  font-size: 14px;
  height: 29px;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.content-para {
  font-size: 15px;
  justify-content: center;
  text-align: center;
}

@media (max-width: 340px) {

  .btn_cart_media span.bought,
  .btn_buy_media {
    font-size: 13px !important;
  }
}

@media (min-width: 1340px) {
  .col-lg-3.artistcard {
    width: 20% !important;
  }
}

span.hearby_condition {
  position: absolute;
  right: 0;
  bottom: 6px;
  display: flex;
  align-items: center;
}

@media (max-width: 1440px) {
  span.hearby_condition {
    position: relative;
    bottom: -5px;
  }
}

.add-edit-media .modal-header {
  padding: 30px 30px;
}

.media-popup {
  .image-media-part {
    // border: 2px solid $base-color;
    border-radius: 10px;
    padding: 10px 21px;
  }

  .modal-header {
    padding: 20px 30px !important;
  }

  .media-body {
    margin-bottom: 20px;
  }

  img.media-avtar {
    height: 300px;
    width: 300px;
    object-fit: contain;
  }

  .media-name {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 47px;
    color: #181818;
    margin-bottom: 5px;
  }

  .media-type {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #181818;
    margin-bottom: 5px;
  }

  .media-description {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 28px;
    color: #6c6c6c;
  }
}

@media (max-width: 990px) {
  .media-popup {
    .media-body {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: auto;
    }

    .col-md-6 {
      width: 100%;
      margin: auto;
    }

    .image-media-part {
      // border: 2px solid $base-color;
      border-radius: 10px;
      padding: 10px 21px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .media-name,
    .media-type,
    .media-description {
      text-align: center;
    }
  }
}

.delete-button-custom-add-artwork {
  right: -3%;
  top: -1%;
  transform: scale(0.45);
  z-index: 999999999999999999 !important;
}

.delete-button-custom-add-artwork:hover {
  cursor: pointer;
}

.front-arrow-add-artwork {
  position: absolute;
  right: -45%;
  top: 45%;
  filter: invert(51%) sepia(5%) saturate(6%) hue-rotate(13deg) brightness(91%) contrast(100%);
  transform: scale(0.7);
}

.front-arrow-add-artwork:hover {
  cursor: pointer;
}

.front-arrow-add-artwork-view-profile {
  right: -44%;
}

@media (max-width: 990px) {
  .front-arrow-add-artwork {
    right: -25px !important;
  }

  .back-arrow-add-artwork {
    left: -25px !important;
  }

  .add-edit-media .modal-header {
    padding: 30px 30px;
  }
}

@media (max-width: 400px) {
  .media-popup .image-media-part {
    // border: 2px solid #75CF8F;
    border-radius: 10px;
    padding: 20px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .add-edit-media .modal-body {
    padding: 0px 20px !important;
  }

  .add-edit-media .modal-header {
    padding: 16px 20px !important;
  }
}

.back-arrow-add-artwork {
  position: absolute;
  left: -45%;
  top: 45%;
  filter: invert(51%) sepia(5%) saturate(6%) hue-rotate(13deg) brightness(91%) contrast(100%);
  transform: scale(0.7);
}

.back-arrow-add-artwork:hover {
  cursor: pointer;
}

.front-arrow-view-artwork {
  position: absolute;
  right: 3%;
  top: 45%;

  filter: invert(51%) sepia(5%) saturate(6%) hue-rotate(13deg) brightness(91%) contrast(100%);
  transform: scale(0.7);
}

.front-arrow-view-artwork:hover {
  cursor: pointer;
}

.middle-alignment-artist-explore {
  padding-top: 5%;
}

.increase-white-bg-artist-explore {
  padding-top: 10%;
}

.bg-green-custom-add-edit-media {
  background-color: $base-color;
  color: white;
}

.uuid_moblie {
  font-size: 12px;
  overflow-wrap: anywhere;
  text-align: center;
}

.email-split {
  overflow-wrap: anywhere;
  text-align: center;
}

.image-media-part {
  .carousel-control-next {
    top: -8% !important;
  }

  .carousel-control-prev {
    top: -8% !important;
  }
}


@media (max-width: 500px) {
  .image-media-part {
    .carousel-control-next {
      top: -13% !important;
    }

    .carousel-control-prev {
      top: -13% !important;
    }
  }
}


@media (max-width:767px) {
  .input-box-EditMedia {
    width: 100% !important;
  }

  .image-upload-section-artist-media {
    margin-top: 22px !important;
    height: 200px;
  }

  .image_upload .upload-pic {
    padding: 9px !important;
  }

}

@media (max-width:600px) {
  img.edit-media-image {
    height: 132px !important;
    width: 200px !important;
    object-fit: contain;
  }
}

@media (max-width:782px) {
  img.edit-media-image {
    height: 125px !important;
    width: 200px !important;
    object-fit: contain;
  }

  .delete-button-custom-add-artwork {
    right: -2%;
    top: -8%;
  }
}


.village_filter_artist,
.district_filter_artist,
.state_filter_artist {
  margin-right: 5px !important;
  width: 100% !important;
  height: 41px !important;
  // border-radius: 5px !important;
  border: none;
  background: white !important;
  color: #6b6b6b !important;
}

.state_filter_artist_notSelected {
  color: #afaeaf !important;
  margin-right: 5px !important;
  width: 100% !important;
  height: 41px !important;
  border: none;
  background: white !important;
}

.carousel-control-next, .carousel-control-prev {
  bottom: 20% !important;
}