.video-thumb {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: opacity 400ms ease 0ms;
}
.tiny {
  filter: blur(20px);
  transform: scale(1.1);
  transition: visibility 0ms ease 400ms;
}

#background-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  animation: fadeOutAnimation ease-out 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 6s;
}

@keyframes fadeOutAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.main-cont {
  /* animation: fadeInAnimation ease-in 2s; */
  animation: fadeInAnimation ease-in 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

#main6 {
  margin-top: 7rem !important;
}

.digital_intro {
  font-style: normal;
  font-weight: 800;
  font-size: 3.5vh;
  text-align: start;
  color: #ffffff;
  margin-bottom: 5px;
}
.digital_text {
  font-style: normal;
  font-weight: 400;
  font-size: 2.3vh;
  color: #ffffff;
  text-align: start;
  margin-bottom: 2px;
}

.pledge_div {
  padding-top: 2rem;
  text-align: start;
}
.pledge_btn {
  background: linear-gradient(
    122.1deg,
    rgba(255, 255, 255, 0.44) 0%,
    rgba(255, 255, 255, 0.07) 50.08%,
    rgba(255, 255, 255, 0.09) 104.51%
  ) !important;
  font-family: "Poppins";
  box-shadow: 0px 0.419604px 2.09802px rgba(0, 0, 0, 0.15),
    inset 0px 0.419604px 4.19604px #b8369c !important;
  backdrop-filter: blur(20.5606px) !important;
  border: 2px solid #ffa800 !important;
  color: #ffa800 !important;
  font-size: 16px !important;
  border-radius: 50px !important;
  padding: 9px 30px !important;
  font-weight: 800 !important;
  letter-spacing: 0.21em;
}

.qutub_cont {
  display: flex;
  justify-content: center;
}

.qutub_img {
  height: 87vh !important;
  /* text-align: start; */
  /* padding-left: 3%; */

  position: absolute;
  bottom: 0;
  left: 50;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  animation: fadeIn ease-in 2s;
  mix-blend-mode: screen !important;
  z-index: -1;
}
.bottomcenter {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex !important;
  justify-content: center;
}
.bottomcenter_qt {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  height: 80vh !important;
  animation: fadeInDown forwards 2s;
}

@media only screen and (max-width: 821px) {
  .digital_intro {
    text-align: center;
    font-size: 3vh !important;
  }
  .digital_text {
    text-align: center;
    font-size: 2vh !important;
  }

  .pledge_div {
    text-align: center;
  }

  .qutub_img {
    height: 50vh !important;
    display: inline;
    padding-left: 0% !important;
  }
}
