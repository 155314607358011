@import url("https://fonts.googleapis.com/css2?family=Orelega+One&family=Poppins:ital,wght@1,200&display=swap");

.bg-main {
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
}
.overlay1234 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: black; /* Adjust the color for the overlay */
  opacity: 0.3; /* Adjust the opacity for the overlay */
}

.overlay124 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: black; /* Adjust the color for the overlay */
  opacity: 0.3; /* Adjust the opacity for the overlay */
}

.pos {
  right: 5rem;
}
.counter {
  font-family: "Orelega One", cursive;
}
.counter-mini {
  font-family: "Orelega One", cursive;
}

.launch {
  font-family: "Orelega One", cursive;
}

.img-win {
  width: 12%;
}
.txt-main {
  margin-right: 10%;
}
.bg-main {
  display: block;
}

.mb-main {
  display: none;
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
}
.mb-door {
  width: 50%;
  height: 50%;
}

@media only screen and (min-height: 600px) {
  .mb-door {
    width: 40%;
    height: 40%;
  }
}
@media only screen and (min-height: 700px) {
  .mb-door {
    width: 50%;
    height: 50%;
  }
}

@media only screen and (max-width: 768px) {
  .bg-main {
    display: none;
  }

  .mb-main {
    display: block;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .pos {
    right: 0.5rem;
  }
  .img-win {
    width: 10.5%;
  }
  .txt-main {
    margin-right: 0.5%;
    font-size: 14px !important;
  }
}

@media only screen and (min-width: 800px) {
  .pos {
    right: 0.1rem;
  }
  .img-win {
    width: 12%;
  }
  .txt-main {
    margin-right: 2%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 950px) {
  .pos {
    right: 2rem;
  }
  .img-win {
    width: 12.5%;
  }
  .txt-main {
    margin-right: 3%;
  }
}

@media only screen and (min-width: 1200px) {
  .pos {
    right: 5rem;
  }
  .img-win {
    width: 13.5%;
  }
  .txt-main {
    margin-right: 5%;
  }
}

@media only screen and (min-width: 1450px) {
  .pos {
    right: 8rem;
  }
  .img-win {
    width: 15%;
  }
  .txt-main {
    margin-right: 5%;
  }
}

@media only screen and (min-width: 1750px) {
  .pos {
    right: 12rem;
  }
  .img-win {
    width: 16%;
  }
  .txt-main {
    margin-right: 10%;
  }
}
