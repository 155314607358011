@media screen and (max-width: 768px) {
  .mobile-scroll {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 480px) {
  span.pl-2 {
    font-size: 10px;
  }

  .ais-SearchBox {
    width: 71% !important;
  }

  img.my-auto.img-fluid.mx-2 {
    margin-left: -1px !important;
  }

  .overview-row-geo.my-4 {
    padding-bottom: 31px;
  }

  .search-content input.ais-SearchBox-input {
    width: 90% !important;
    font-size: 11px;
  }

  span.pl-2 {
    display: none !important;
  }

  .th-13.py-2.px-3 {
    padding-bottom: 60px !important;
  }

  .container-fluid.pt-3 {
    padding-bottom: 60px !important;
  }

  .ais-SearchBox-form {
    position: relative;
  }

  .search-content button.ais-SearchBox-submit {
    position: absolute;
    top: 0;
    right: 21px !important;
    padding: 13px 20px !important;
  }
}

ul.custom-pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  justify-content: center;
}

ul.custom-pagination li {
  padding: 0.375rem 0.75rem;
  position: relative;
  display: block;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

ul.custom-pagination li.disabled {
  color: #c7c7c7;
}

.load-more-button {
  width: fit-content;
  border-radius: 5px;
  padding: 8px 25px;
  color: #fff;
  background: #75cf8f;
  border: none;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
}
/* 
.load-more-button:hover {
  border: 1px solid #00ab65;
} */
.load-more-button:active {
  background-color: #00ab65;
}
@media screen and (min-width: 480px) and (max-width: 630px) {
  .search-content input.ais-SearchBox-input {
    width: 87% !important;
    padding: 12px;
  }

  .search-content button.ais-SearchBox-submit {
    position: absolute;
    top: 0;
    right: 14px;
    padding: 12px 37px !important;
  }
}

select.menu-select {
  width: 200px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0.683333px 3.99192px rgb(0 0 0 / 18%);
  border-radius: 4px;
  color: #3aae5b;
  font-weight: 600;
  font-size: 14px;
  border: none;
  padding: 0 2px;
}

select.menu-select option {
  color: #000;
}

.filter-clear-part button {
  width: 140px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0.683333px 3.99192px rgb(0 0 0 / 18%);
  border-radius: 4px;
  color: #3aae5b;
  font-weight: 600;
  font-size: 14px;
  border: none;
  padding: 0 5px;
  margin-left: 3px;
}

button.ais-ClearRefinements-button.ais-ClearRefinements-button--disabled {
  opacity: 0;
}

.ais-SearchBox-form {
  position: relative;
}

.search-content button.ais-SearchBox-submit {
  position: absolute;
  top: 0;
  right: 21px;
  padding: 15px 52px;
}

@media screen and (min-width: 360px) and (max-width: 413px) {
  .search-content input.ais-SearchBox-input {
    width: 88% !important;
    font-size: 11px;
  }

  .search-content button.ais-SearchBox-submit {
    position: absolute;
    top: 0;
  }

  .mobile-scroll {
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 280px) and (max-width: 361px) {
  .search-content input.ais-SearchBox-input {
    width: 91% !important;
    font-size: 11px;
    padding: 11px !important;
  }

  .search-content button.ais-SearchBox-submit {
    position: absolute;
    top: 0;
    right: 5px !important;
    padding: 9px 20px !important;
  }
}

@media (max-width: 580px) {
  select.menu-select,
  .filter-clear-part button {
    width: 100% !important;
    margin: 2px 0;
  }

  .filter-part,
  .drop-down-filter.d-flex {
    flex-direction: column;
  }
}

.add-village-btn-custom {
  border: none !important;
  border-radius: 10px;
  color: white;
  padding: 15px 0px;
}

.clear-filter-btn {
  color: #3aae5b !important;
  background-color: white !important;
  font-size: 0.95rem !important;
  font-weight: 500 !important;
}

@media (min-width: 310px) and (max-width: 361px) {
  .add-village-btn-custom {
    font-size: 0.7rem;
    padding: 17px 9px !important;
  }
}

@media (min-width: 280px) and (max-width: 310px) {
  .add-village-btn-custom {
    font-size: 0.7rem;
    padding: 9px 9px !important;
  }
}

.category-name-village-card {
  background-color: #92e3a9;
  padding: 2px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5%;
  color: #0f371b !important;
  right: 0;
  text-transform: capitalize !important;
}

.village-card-main-container {
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.load {
  width: 40px;
  height: 40px;
  display: inline-block;
  border: solid 6px #75cf8f;
  border-radius: 50%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  -webkit-transition: all 0.5s ease-in;
  -webkit-animation-name: rotate;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  transition: all 0.5s ease-in;
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.ytp-button {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 68px;
  height: 48px;
  margin-left: -34px;
  margin-top: -24px;
  -webkit-transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
  -o-transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
  z-index: 63;
  border: none;
  background-color: transparent;
  padding: 0;
  color: inherit;
  text-align: inherit;
  font-size: 100%;
  font-family: inherit;
  cursor: default;
  line-height: inherit;
}

.ytp-button:hover {
  transition: 0.1s;
  transform: scale(1.2);
}
.myModal {
  --bs-modal-bg: transparent !important;
  background: #1d1d1de3 !important;
}

.video-responsive iframe {
  padding: 0px !important;
}
