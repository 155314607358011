.slick-dots {
  position: absolute;
  bottom: -28px;
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.th-btn-slider {
  line-height: 15px !important;
  background-color: #fff !important;
  height: 60px !important;
  width: 35px !important;
  z-index: 1000;
}

.th-btn-slider img {
  height: 18px;
  width: 18px;
}

.slick-prev:before,
.slick-next:before {
  font-size: 0px !important;
}

.action-btn {
  color: #6b6b6b;
  padding: 0 4px 4px 4px;
}

.selectiveColor {
  background-color: #75cf8f;
  border-radius: 5px;
  padding: 4px 4px 4px 4px;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .th-btn-slider {
    line-height: 2px !important;
    background-color: #fff !important;
    height: 50px !important;
    width: 30px !important;
    z-index: 1000;
  }

  .th-btn-slider img {
    height: 15px;
    width: 25px;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 0px !important;
  }

  .slick-prev {
    left: -3px;
  }

  .slick-next {
    right: -3px;
  }

  .overview-card-row {
    height: 200px;
    // overflow-x: auto;
    // white-space: nowrap;
    min-width: 250px !important;
  }

  .gallery_thumnail {
    height: 35% !important;
    width: 44% !important;
    margin: 2% !important;
    border-radius: 5px !important;
    background-position: top !important;
    position: relative !important;
  }

  // .gallery_thumnail div {
  //   display: flex;
  //   justify-content: center;
  // }
  .gallery_thumnail div img {
    border-radius: 5px !important;
    height: 25vh !important;
    width: 40vw !important;
  }

  .overlay {
    position: absolute;
    border-radius: 0px 0px 5px 5px;
    bottom: 0;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5);
    /* Black see-through */
    color: #f1f1f1;
    width: 100%;
    transition: 0.5s ease;
    opacity: 0;
    top: auto;
    color: white;
    font-size: 0.74vh !important;
    padding: 2%;
    text-align: center;
  }

  .overview-row-geo .bedu .overview-card {
    width: 100% !important;
  }

  .overview-row-geo .bedu {
    display: inline-block !important;
  }
}

.overview-row-geo {
  white-space: unset !important;
  width: 100%;
  margin: auto;
  padding-right: 0 !important;
}

.overview-row-geo .bedu {
  width: 100%;
  margin: 0 !important;
}

.overview-row-geo .overview-card {
  width: unset !important;
  margin: 5px 0;
  margin-right: 2% !important;
}

/* Custom Slick Dots */

// @keyframes loading {
//     from {
//       width: 0%;
//     }

//     to {
//       width: 100%;
//     }
//   }

.ft-slick__dots--custom {
  height: 8px;
  width: 35px;
  background-color: #e5e7e9;
  border-radius: 4px;
  position: relative;
}

.slick-dots li {
  width: 35px;
  margin: 0 2px;
  // transition: width 0.3s ease-in-out;
}

.slick-dots .slick-active {
  width: 35px;
  // transition: width 0.3s ease-in-out;
}

.slick-dots .slick-active .ft-slick__dots--custom {
  width: 35px;
  top: 0px;
  overflow: hidden;
  background-color: #75cf8f;
}

.th-details-card {
  -webkit-box-shadow: 0px 1px 7px 0px rgba(150, 150, 150, 0.85);
  -moz-box-shadow: 0px 1px 7px 0px rgba(150, 150, 150, 0.85);
  box-shadow: 0px 1px 7px 0px rgba(150, 150, 150, 0.85);
}

// ------------------------------------------------------------------------------------------------------------------------------------------------

.video-responsive {
  width: 100%;
  height: 0;
  // padding-bottom: 57.7%;
  /* Change this till it fits the dimensions of your video */
  position: relative;
  padding-bottom: 56.8%;
  /* 16:9 */
  padding-top: 4px;
}

.video-responsive iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
}

.village_video :focus {
  outline: 1px solid white !important;
  border: 1px solid white;
}

::-webkit-scrollbar {
  width: 8px;
  height: 2em;
}

// .video-responsive {
//   overflow: hidden;
//   padding-bottom: 56.25%;
//   position: relative;
//   height: 0;
//   border-radius: 10px;
// }

// .video-responsive iframe {
//   left: 0;
//   top: 0;
//   height: 100%;
//   width: 100%;
//   position: absolute;
//   border-radius: 10px;
// }

.photo-responsive {
  overflow: hidden;
  padding: 16px !important;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  border-radius: 10px;
}

.hide {
  display: none;
}

.show {
  display: block;
}

// .photo-responsive img {
//   left: 0;
//   top: 0;
//   height: 100%;
//   width: 100%;
//   position: absolute;indicators
//   border-radius: 10px;
// }

.carousel-caption {
  position: static !important;
  text-align: start;
  padding-left: 1rem;
  padding-bottom: 0%;
}

.carousel-indicators {
  bottom: -43px !important;
}

.carousel-indicators [data-bs-target] {
  background-color: #75cf8f !important;
}

.overview-card-row {
  height: auto;
  // overflow-x: auto;
  // overflow-y: hidden;
  // width: 1000px;
  // display: inline-block;
  // white-space: nowrap;
  // min-width: 280px;
  // padding-right: 20% !important;
}

.overview-row-geo {
  height: auto;
  white-space: nowrap;
}

.offcanvas-start {
  width: 60%;
}

.bedu {
  display: inline-block;
}

/* SCROLLBAR */

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}
@media (max-width: 768px) {
  ::-webkit-scrollbar {
    width: 8px;
    height: 5px;
    display: none;
  }
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(186, 185, 185, 0.741);
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #75cf8f;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #80d498;
}

.length-chars {
  width: 35ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// .carousel-control-next {
//   height: 80%;
// }

// .carousel-control-prev {
//   height: 80%;
// }

.ytp-title-text {
  padding-top: 347px !important;
  color: #f6f5f400 !important;
}

.ytp-watermark {
  z-index: -1 !important;
  bottom: -37px;
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}

.ytp-chrome-top {
  display: none !important;
}

.image {
  display: block;
  width: 100%;
  height: 60%;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 20%;
  max-height: 50%;

  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
}

.container:hover .overlay {
  opacity: 1;
}

.text {
  color: Red;
  font-size: 20px;
  position: absolute;
  bottom: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  pointer-events: none;
}

.carousel-indicators {
  list-style: none;
}

.carousel-indicators li,
.carousel-indicators li.active {
  width: 70px;
  height: 70px;
  background-color: #fff;
  position: relative;
  margin: 10px;
}

.carousel-indicators img {
  position: absolute;
  width: 100%;
  border-radius: 50%;
  height: 100%;
  top: 0;
  left: 0;
}

.carousel-control-prev-icon {
  background-color: rgba(0, 0, 0, 0.759);
  color: #e5e7e9;
  border: 5px solid #2b333f00;
  border-radius: 50%;
  // padding: 5%;
}

.carousel-control-next-icon {
  background-color: rgba(0, 0, 0, 0.759);
  color: #e5e7e9;
  border: 5px solid #2b333f00;
  border-radius: 50%;
  // padding: 5%;
}

.carousel-inner {
  border-radius: 10px;
}

@media screen and (max-width: 1460px) {
  .topic_bar {
    overflow-x: scroll;
  }

  // .ril__caption {
  //   bottom: 10% !important;
  //   font-size: 1.5vh;
  // }
  // .ril__toolbar {
  //   top: 7% !important;
  // }
  // .ril__toolbarRightSide {
  //   padding-top: 0% !important;
  // }
}

// @keyframes mymove {
//   50% {
//     box-shadow: 10px 0px 5px #a4deb5d2;
//   }
// }
// #offCanv {
//   animation: mymove 3s infinite;
// }

// ---------------------------------------------------GALLERY------------------------------------------------------------------------------
.date_btn {
  border-radius: 25px;

  padding: 8px;
  border: 2px solid #75cf8f !important;
}

.not_selected_date {
  border-color: #75cf8f !important;
  color: #6b6b6b;
}

.gallery_thumnail {
  height: 40% !important;
  width: 26% !important;
  margin: 2%;
  border-radius: 5px;
  background-position: top !important;
  position: relative;
  overflow: hidden;
  padding-left: 0%;
  padding-right: 0%;
  cursor: pointer;
}

.btn_360 {
  all: unset;
  position: absolute !important;
  top: 5px;
  right: 5px;
  width: 40px;
  padding: 5px;
  background-color: #74c18a9a;
  border-radius: 8px;

  &:hover {
    background-color: #40b05e;
  }
}

.close_btn_model {
  border: none;
  background: #75cf8f;
  padding: 7px 25px;

  &:hover {
    background-color: #40b05e;
  }
}

.view3dImage_body {
  height: 100%;
}

.gallery_thumnail div img {
  border-radius: 5px !important;
  height: 60vh;
  min-width: 20vw;
}

.gallery_thumnail:hover .overlay {
  opacity: 1;
}

.overlay {
  position: absolute;
  border-radius: 0px 0px 5px 5px;

  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  /* Black see-through */
  color: #f1f1f1;
  width: 100% !important;
  transition: 0.5s ease;
  opacity: 0;
  top: auto;
  color: white;
  font-size: 1.5vh;
  padding-top: 2%;
  padding-left: 4px;
  padding-right: 4px;
  text-align: center;
}

// .ril__toolbarRightSide {
//   padding-top: 5%;
// }
.ReactModal__Overlay--after-open {
  z-index: 1000000 !important;
}

.display-static.carousel-caption {
  display: flex;
  justify-content: space-between;
  padding-right: 1rem;
  align-items: center;
}

.cutom_footer_space,
.culture_profile {
  .carousel-control-next,
  .carousel-control-prev {
    height: fit-content;
    margin: auto;
  }
}

a.carousel-control-next,
a.carousel-control-prev {
  margin: auto;
  height: fit-content;
}

.primary-part {
  width: 100%;
}

@media (max-width: 767px) {
  .common_mobile_icon img {
    height: 14px !important;
    width: 14px !important;
  }
}

.carsouel-main .carousel-control-prev-icon {
  visibility: hidden !important;
}

.carsouel-main .carousel-control-next-icon {
  visibility: hidden;
}

.text-badge {
  // padding-bottom: 1px;
  text-align: center;
  font-size: 17px;
}

.clickme {
  color: rgb(117, 207, 143);
  font-size: 20px;
  // border-bottom: 3px solid black;
}

.dynamic-button-add {
  font-weight: 400;
  font-size: 15.3957px;
  line-height: 18px;
  color: #40b05e;
}

.dynamic-button-add .plus-icon {
  filter: invert(61%) sepia(56%) saturate(504%) hue-rotate(84deg)
    brightness(87%) contrast(83%);
  transform: scale(0.65);
  padding: 0 0 2px 0;
}

.dynamic-button-add:hover {
  cursor: pointer;
}

.delete-icon-custom {
  position: absolute;
  top: 0;
  right: 0;
}

.label_form-temp {
  opacity: 0;
}

.delete-button-custom {
  right: -5%;
  top: -20%;
  transform: scale(0.65);
}

.delete-button-custom:hover {
  cursor: pointer;
}

.plus-icon-custom {
  filter: invert(61%) sepia(56%) saturate(504%) hue-rotate(84deg)
    brightness(87%) contrast(83%);
  transform: scale(2.2);
}

.plus-with-a-line .img-custom-plus {
  margin: 10px 0 20px;
  padding-top: 5px;
  transition: 0.3s;
}

.plus-with-a-line .img-custom-plus:hover {
  transform: scale(2.1);
  transition: 0.3s;
  cursor: pointer;
}

.img-custom-minus {
  padding-left: 1rem;
  position: absolute;
  right: 0;
  top: 0;
}

@media screen and (max-width: 400px) {
  .gallery_thumnail {
    height: 35% !important;
    width: 95% !important;
    margin: 2% !important;
    border-radius: 5px !important;
    background-position: top !important;
    position: relative !important;
  }

  .logo_360 {
    height: 18px;
  }

  .btn_360 {
    width: 32px;
  }
}

@media (max-width: 1415px) and (min-width: 768) {
  .image_gallery_360 {
    height: 70vh !important;
  }
}

.invalid {
  border: 1px solid #ff0000;
}

@media (max-width: 1000px) and (min-width: 768px) {
  .custom-class-for-adding-listener {
    width: 80% !important;
  }
}

.highlight {
  background-color: yellow;
}
