// font sizes
@for $i from 1 through 100 {
    .th-#{$i} {
      font-size: $i + px;
    }
  }

  @media only screen and (min-width: 1440px) {
  @for $i from 1 through 100 {
    .th-#{$i} {
      font-size: $i+4 + px;
    }
  }
}
@media only screen and (max-width: 767px) {
  @for $i from 1 through 100 {
    .th-#{$i} {
      font-size: $i+(-1) + px;
    }
  }
}
  // font weight
  @for $i from 100 through 900 {
    @if $i % 100==0 {
      .th-fw-#{$i} {
        font-weight: $i
      }
    }
  }

//   border radius
@for $i from 1 through 100 {
    .th-br-#{$i} {
      border-radius: $i + px;
    }
  }

//   height th-ht-100
@for $i from 1 through 100 {
  .th-ht-#{$i} {
    height: $i + vh;
  }
}