/* header css  */
.header-transparent-part {
    // box-shadow: inset 0 27px 40px 9px #bababa;   
    padding: 4px 0;
}

.dropdown-toggle.user_login_dots::after {
    display: none !important;
    border: none !important;
}

.logoSection {
    margin-left: 1%;
}

@media (max-width: 1030px) and (min-width: 768px) {
    .web-view-header {
        left: 35%;
    }

    .header-part .tablet-responsive.last-image {
        width: 85px !important;
        margin: 0 8px !important;
    }
}

@media (max-width: 850px) and (min-width: 770px) {
    .web-view-header {
        left: 34%;
    }
}

@media (max-width: 770px) and (min-width: 760px) {
    .web-view-header {
        left: 31%;
    }

    img.my-auto.img-fluid.mx-2.tablet-responsive {
        padding-right: 0px !important;
        margin: 3px !important;
    }

    .header-part .tablet-responsive.last-image {
        width: 70px !important;
        margin: 0 4px !important;
    }

    .logoSection {
        margin-left: 0% !important;
    }
}

button:focus,
button:active {
  outline: none;
  box-shadow: none;
  background-color: none;
}
.dropdown-item:focus,
.dropdown-item:active {
  outline: none;
  box-shadow: none;
  background-color: #f3f3f3 !important;
}
.dropdown{
    --bs-dropdown-link-active-bg: #f4f4f4 !important;
}