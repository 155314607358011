.upload {
  box-sizing: border-box;
  padding: 5.83251px 23.33px;
  gap: 17.5px;
  margin-top: 20px;
  position: relative;
  width: 160.82px;
  height: 40.33px;
  background: #F7F7F7;
  border: 1.65992px solid #75CF8F;
  border-radius: 8.29961px;
}

.modal-width {
  width: 70%;
}

.modal-content {
  width: 100%;
}

.image-edit-model .modal-dialog {
  max-width: 400px !important;
}

.modal-dialog.modal-width.mobile-theme {
  margin: auto;
}

@media screen and (max-width: 400px) {
  .upload.select-file {
    display: flex;
    align-items: center;
    width: auto;
    justify-content: center;
  }

  .upload.select-file img {
    position: relative !important;
  }
}

.image-edit-model .modal-dialog {
  margin: auto !important;
}