.ticker-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; 
  background-color: #3939A0;
  font-family: sans-serif;
  margin-top: 5%;
}

@media (max-width: 600px) { /* For mobile devices */
  .ticker-container {
    margin-top: 17%;
  }
}

.static-text {
  font-size: 17px;
  color: #ffffff;
  white-space: nowrap; /* Prevents text from wrapping */
  animation: scroll-text 10s linear infinite; /* Animate the text scrolling */
  cursor: pointer;
}

@keyframes scroll-text {
  0% {
    transform: translateX(100%); /* Start from right */
  }
  100% {
    transform: translateX(-100%); /* End at left */
  }
}