.heading {
  font-size: 16px;
  font-weight: bold;
  color: #455a64;
}

.back {
  margin: 20px;
  background-color: white;
  padding-left: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
}

.loadtxt {
  font-size: 25px;
  font-weight: bold;
  margin: auto;
}

@media (max-width:768px) {
  .no-recent-badge {
    opacity: 0.5;
    background: #919f9b;
    background-image: url("./svgList/lock.svg") !important;
    background-size: 300px 70px !important;
    background-repeat: no-repeat;
    background-position: center;

  }

  .new-recent-badge {
    background-image: url("./svgList/lock.svg") !important;
    background-size: 70px 70px !important;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@media (min-width: 600px) and (max-width: 765px) {
  .artimage {
    width: 135px !important;
    height: 135px !important;
  }

  .carousel-slide {
    background: black;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    width: 100%;
    height: auto;
  }

  .carousel-item {
    height: 7rem;
    min-width: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1);
  }
}

@media (min-width: 530px) and (max-width: 600px) {
  .artimage {
    width: 120px !important;
    height: 120px !important;
  }

  .badges {
    padding-bottom: 84px;
  }
}

@media (min-width: 425px) and (max-width: 530px) {
  .artimage {
    width: 95px !important;
    height: 95px !important;
  }

  .badges {
    padding-bottom: 84px;
  }
}

@media only screen and (max-width: 425px) {
  .scroll {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .badges {
    font-size: 4vw;
    padding-bottom: 102px;
  }

  .artimage {
    width: 85px !important;
    height: 85px !important;
  }

  .no-recent-badge {
    opacity: 0.5;
    background: #919f9b;
    background-image: url("./svgList/lock.svg") !important;
    background-size: 300px 40px !important;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@media only screen and (max-width: 385px) {
  .artimage {
    width: 75px !important;
    height: 75px !important;
  }

  .badges {
    font-size: 4vw;
    padding-bottom: 140px;
  }
}

@media only screen and (max-width: 350px) {
  .artimage {
    width: 85px !important;
    height: 85px !important;
  }

}

@media only screen and (max-width: 303px) {
  .artimage {
    width: 75px !important;
    height: 75px !important;
    font-size: 15px;
  }
}

@media (max-width: 1400px) {
  .web-part-two .artimage {
    height: 120px !important;
    width: 120px !important;
  }
}

@media (max-width: 1500px) and (min-width: 1100px) {
  .web-part-two .artimage {
    height: 120px !important;
    width: 120px !important;
  }

  .artimage {
    width: 90px !important;
    height: 90px !important;
  }

  .no-recent-badge {
    opacity: 0.5;
    background: #919f9b;
    background-image: url("./svgList/lock.svg") !important;
    background-size: 300px 70px !important;
    background-repeat: no-repeat;
    background-position: center;

  }
}

@media (max-width: 1200px) and (min-width: 768px) {
  .web-part-two .artimage {
    height: 95px !important;
    width: 95px !important;
  }

  .artimage {
    width: 90px !important;
    height: 90px !important;
  }

  .back {
    margin-top: 5px !important;
  }

  .no-recent-badge {
    opacity: 0.5;
    background: #919f9b;
    background-image: url("./svgList/lock.svg") !important;
    background-size: 300px 50px !important;
    background-repeat: no-repeat;
    background-position: center;

  }
}

@media (min-width: 768px) and (max-width: 790px) {
  .back {
    margin-top: 0px !important;
  }
}

.react-multiple-carousel__arrow {
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
}

.artimage {
  opacity: 0.5;
  position: relative;
  background-color: black;
}

.artimage::after {
  content: "";
  background-image: url("./svgList/lock.svg");
  position: absolute;
  height: 100%;
  width: 100%;
}

.lock-img {
  position: absolute;
  left: 35%;
  top: 35%;

}

.loadtxtM {
  font-size: 15px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
}

img.artimage.mobile {
  opacity: 1;
}

.no-recent-badge {
  opacity: 0.5;
  background: #919f9b;
  background-image: url("./svgList/lock.svg") !important;
  background-size: 300px 200px;
  background-repeat: no-repeat;
  background-position: center;

}

.recentLoading {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.recentLoading.badges {
  margin-top: 80px;
}

._dialog_overlay,
dialog+.backdrop {
  position: relative !important;
}

@media (min-width:1500px) {
  .col-8.px-2 .no-recent-badge {
    opacity: 0.5;
    background: #919f9b;
    background-image: url("./svgList/lock.svg") !important;
    background-size: 300px 200px !important;
    background-repeat: no-repeat;
    background-position: center;

  }

  .col-4.px-2 .no-recent-badge {
    opacity: 0.5;
    background: #919f9b;
    background-image: url("./svgList/lock.svg") !important;
    background-size: 300px 126px !important;
    background-repeat: no-repeat;
    background-position: center;
  }

  .recent-img img {
    width: 190px;
    height: 190px;
    padding: 0px;
  }
}

@media (min-width:1550px)and (max-width:1750px) {
  .recent-img img {
    width: 170px !important;
    height: 170px !important;
  }
}

@media (min-width:1320px)and (max-width:1550px) {
  .recent-img img {
    width: 140px !important;
    height: 140px !important;
  }
}

@media (min-width:1150px)and (max-width:1320px) {
  .recent-img img {
    width: 120px !important;
    height: 120px !important;
  }
}

@media (min-width:1050px)and (max-width:1150px) {
  .recent-img img {
    width: 100px !important;
    height: 100px !important;
  }
}

@media (max-width:1050px) {
  .recent-img img {
    width: 120px !important;
    height: 120px !important;
  }
}

@media (max-width:908px) {
  .recent-img img {
    width: 100px !important;
    height: 100px !important;
    padding: 5px !important;
  }
}

img.isvisible {
  opacity: 1;
}