.leader2 {
  height: 80px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 80px;
  border: 3px solid white;
  box-shadow: 3.7052px 3.7052px 24.7013px rgba(174, 174, 192, 0.5);
  position: relative;
}

.leader2-badge {
  position: absolute;
  z-index: 1;
  height: 23px;
  width: 23px;
  right: -13px;
  top: 19px;
  display: grid;
  align-items: center;
  border: 2px solid white;
}

.leader {
  position: realtive;
  height: 120px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 120px;
  border: 3px solid white;
  box-shadow: -6.17534px -6.17534px 24.7013px #ffffff,
    6.17534px 6.17534px 24.7013px rgba(174, 174, 192, 0.5);
}

.leader-badge {
  z-index: 1;
  height: 30px;
  width: 30px;
  float: right;
  margin-top: -35px;
  border: 3px solid white;
  position: absolute;
  right: 0;
  left: 85px;
  top: 24px;
}

.leader-m-badge {
  z-index: 1;
  height: 30px;
  width: 30px;
  position: absolute;
  right: 1px;
  bottom: 10px;
  border: 2px solid white;
}

.image-part {
  position: relative;
}

.part-one-desk {
  gap: 22px;
}

.leader-image {
  position: absolute;
  bottom: -21px;
  left: 32px;
}

.text-part {
  padding-left: 110px;
}

.part-two-1 {
  display: flex;
  width: 40%;
  justify-content: space-between;
  align-items: center;
}

.mobile-leader-part {
  width: 35%;
}

@media screen and (max-width: 390px) {
  .part-two-1 {
    width: 49%;
  }

  .d-flex.py-3.px-4.justify-content-between.align-items-center.leaderboard-design {
    padding: 14px 7px !important;
  }

  .name.text-left {
    font-size: 10px !important;
    margin-right: 1px;
  }

  .email.text-left,
  .remain-list {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 50px;
  }

  .part-img {
    width: 25px !important;
  }

  .search-content button.ais-SearchBox-submit {
    top: 10px !important;
  }

  .col-3.px-3.d-flex.justify-content-end.th-12.text-capitalize.th-black {
    margin-left: 12px;
  }

  img.part-img {
    height: 30px;
  }
}

.part-img {
  width: 35px;
}

.first-part {
  border: 2px solid #ffc107;
  box-shadow: 0 3px 10px rgb(255 193 7 / 50%);
  padding: 2px 5px !important;
  justify-content: space-between;
}

.override-first-part {
  padding: 0 !important;
}

.second-part {
  border: 2px solid #a39b9b;
  box-shadow: 0 3px 10px rgb(163 155 155 / 50%);
}

.thired-part {
  border: 2px solid #cd7f32;
  box-shadow: 0 3px 10px #d78946;
}

.part-two-desk {
  display: flex;
  width: 36%;
  justify-content: inherit;
  align-items: center;
}

.part-two {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 1000px) {
  .text-center.rounded-circle.leader2.web-part {
    margin-left: 10px !important;
  }

  .image-part {
    margin-left: -45px;
  }

  .text-left.th-16.th-fw-500.th-grey-4 {
    text-align: initial;
  }
}

@media (max-width: 400px) {
  .searchcard {
    padding-bottom: 70px !important;
  }
}
